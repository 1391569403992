.homepage {
  position: relative;
  background: url('../images/home3.jpeg') no-repeat center center/cover;
  height: 100vh;

  &-inner {
    color: $white-color;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  h1 {
    color: $white-color;
    font-size: 250%;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
  }
  p {
    font-size: large;
    font-weight: 400;
  }

  .home-content {
    background: rgba(0, 0, 0, 0.3);
    padding: 2rem 8rem;
    border-radius: 4px;
    .home-text-light {
      color: darken($white-color, 30%);
      span {
        color: $white-color;
      }
    }
  }
}
.home-overlay {
  background: rgba(0, 0, 0, 0.2);
  height: 100vh;
}
.alert-section {
  position: absolute;
  top: 63px;
  right: 0;
}
