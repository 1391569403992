.login-form-wrap {
  margin: 80px auto;
  max-width: 300px;
  height: 80vh;
}
.login-form-title {
  text-align: center;
  img {
    height: 44px;
    margin-right: 16;
  }
}
.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
  margin-bottom: 0.5rem;
}
.login-form-register-link-wrapper {
  float: right;
}
.site-form-item-icon {
  svg {
    color: rgba(0, 0, 0, 0.25);
  }
}
