.ant-layout-footer {
  background: #111 !important;
  color: #fff !important;
  padding: 20px 0 !important;
  text-align: center;
  height: $heightFooter;
  // position: absolute;
  // left: 0;
  // bottom: 0;
  // right: 0;
}
.footer .footer-logo {
  font-size: 1.2rem;
  text-transform: uppercase;
  margin: 0 0 1rem;
  font-weight: 500;
  a {
    color: $white-color;
    &:hover {
      color: $primary-color;
    }
  }
}
.footer-inner {
  color: $white-color;
}
.ant-back-top {
  svg {
    color: $primary-color;
    font-size: 1.6rem;
    &:hover {
      opacity: 0.7;
    }
  }
}

.footer .footer-socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
  margin-bottom: 0.5rem;

  li {
    margin: 0 10px;
  }
  a {
    color: $white-color;
  }
  a:hover {
    color: $primary-color;
  }
}
